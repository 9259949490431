import { ICreationInfo, ICampaignSendWindow } from 'api/response'
import { AlertBanner, AlertType } from 'components/Alert/Alert'
import { RecipientGroup } from 'components/CalendarPopover/CalendarPopover'
import { CampaignAudienceSection } from 'components/CampaignAudienceSection/CampaignAudienceSection'
import { calculateResponsePercentage } from 'components/CampaignCard/CampaignCard'
import { CampaignEngagementSection } from 'components/CampaignEngagementSection/CampaignEngagementSection'
import { CampaignImportSection } from 'components/CampaignImportSection/CampaignImportSection'
import { CampaignPreviewCard } from 'components/CampaignPreviewCard/CampaignPreviewCard'
import { CampaignResultsCard } from 'components/CampaignResultsCard/CampaignResultsCard'
import { FilterIcon } from 'components/Icons/FilterIcon/FilterIcon'
import { MainstayModal } from 'components/Modal/Modal'
import { Chip } from 'components/Chip/Chip'
import { noOp } from 'util/noOp'

import { CenteredLoader } from 'components/Loader/Loader'
import { Pill } from 'components/Pill/Pill'
import TabbedView, { ITabbedViewOption } from 'components/TabbedView/TabbedView'
import { addDays, addMinutes } from 'date-fns'
import { clamp, isNil, capitalize } from 'lodash'
import {
  ReadOnlyContactSegmentSelect,
  ISegmentOption,
} from 'components/ContactSegmentSelect/ContactSegmentSelect'
import moment from 'moment-timezone'
import {
  CampaignContainer,
  useGetCampaignSidebarLinks,
} from 'page/campaign/CampaignHistory'
import 'page/CampaignDetailsPage.scss'
import NavBarPage from 'page/NavBarPage'
import * as React from 'react'
import { useLocation, useParams } from 'react-router'
import { push } from 'connected-react-router'
import { getGlobalAppropriateTimeSettings } from 'store/campaign-scheduler/selectors'
import {
  getAggregateResults,
  getCampaignImportDetails,
  getResults,
  getSelectedCampaign,
  getWorkflowInitialState,
} from 'store/campaign-details/selectors'
import {
  fetchCampaignDetailsAsync,
  fetchCampaignImportDetailsAsync,
  fetchRecurringCampaignDetailsAsync,
  generateCampaignReportAsync,
} from 'store/campaign-details/thunks'
import {
  ICampaign,
  ICampaignTriggerDetails,
  ICampaignByDialogDetails,
} from 'store/campaign-history/reducer'
import { IRecurrenceSettings } from 'store/campaign-scheduler/reducer'

import { RepeatInterval, DateRepeatInterval } from 'util/dateRepeatIntervals'
import { getDeletedDates } from 'page/calendar/Calendar'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import { getInstitutionTimeZone } from 'store/triage/profile/selectors'
import {
  HttpErrorKind,
  isFailure,
  isSuccess,
  WebDataError,
} from 'store/webdata'
import strftime from 'strftime'
import { useSelector, useDispatch, useFeatures } from 'util/hooks'
import { CampaignHistorySection } from 'components/CampaignHistorySection/CampaignHistorySection'
import { generateReportFileName } from 'components/TrendsV2/CustomizedDownloadModal'
import { AdmithubOnlyButton } from 'components/Button/Button'
import { rescheduleFailedCampaign, updateRecurringCampaignEndDate } from 'api'
import { isLeft, isRight } from 'fp-ts/lib/These'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { updateQueryString } from 'util/string'
import { getQueryFilters } from 'util/queryFilters'
import { ParsedQuery } from 'query-string'
import { secondsToDHMS } from 'util/timeConversion'
import { NATIONAL_USER } from 'page/users-v2/RegionSelect'
import { usePermissionsContext } from 'util/permissions/PermissionsContext'
import { PERMISSIONS } from 'util/permissions/permissions'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import classnames from 'classnames'
import Tooltip from 'components/Tooltip/Tooltip'
import { RecurringCampaignEndDatePicker } from 'components/CampaignTrigger/CampaignTrigger'
import { updateRecurringCampaignEndDateAction } from 'store/campaign-details/actions'

interface ICampaignDetailsPageProps {
  readonly isRecurringInstance: boolean
}

const humanizedIntervals = {
  [RepeatInterval.noRepeat]: 'Once',
  [RepeatInterval.daily]: 'Every day',
  [RepeatInterval.weekly]: 'Every week',
  [RepeatInterval.monthly]: 'Every month',
  [RepeatInterval.weekdays]: 'Every weekday [Monday to Friday]',
  [RepeatInterval.weekends]: 'Every weekend day [Saturday & Sunday]',
}

const NO_FILTER = undefined

// Size (in rem) of title at start and end of scroll-based transition
const TITLE_SIZE_START = 1.563
const TITLE_SIZE_END = 1.25
// Scroll amounts (in pixels) where title and description transitions start and stop
const TITLE_RESIZE_START = 20
const TITLE_RESIZE_END = 90
const DESCRIPTION_FADE_START = 20
const DESCRIPTION_FADE_END = 65

interface ICampaignDetailContainerProps {
  readonly error?: boolean
  readonly loading?: boolean
  readonly children?: React.ReactNode
  readonly errorMsg?: string
}

function CampaignDetailContainer({
  error,
  errorMsg,
  loading,
  children,
}: ICampaignDetailContainerProps) {
  return (
    <NavBarPage
      title="Campaigns"
      className="h-100 m-0"
      pageMainClassName="page-campaign"
      error={error}
      loading={loading}
      errorMsg={errorMsg}>
      {children}
    </NavBarPage>
  )
}

interface IFailureFetchingCampaign {
  readonly error: WebDataError
}

export const MOMENT_DATETIME_DISPLAY_FORMAT = 'MM/DD/YYYY, hh:mmA'
const MOMENT_DATE_DISPLAY_FORMAT = 'MM/DD/YYYY'

function FailureFetchingCampaign({ error }: IFailureFetchingCampaign) {
  let errorMessage: string | undefined = undefined
  if (error === HttpErrorKind.error404) {
    errorMessage = '404: could not find that campaign'
  } else if (error === HttpErrorKind.timeout) {
    errorMessage = 'Timeout: please refresh the page and try again'
  }
  return <h3 className="text-mainstay-dark-blue p-5">{errorMessage}</h3>
}

export function LoadingCampaign() {
  return <CampaignDetailContainer loading />
}

/*
  Campaign Insights components with updated design and metrics.
*/

export const OverviewRow = ({
  field,
  value,
  children,
}:
  | {
      field: string
      value: string | number
      children?: undefined
    }
  | { field: string; value?: undefined; children: React.ReactNode }) => (
  <div className="overview-row">
    <span className="fw-600 space-after">{field}:</span>
    {value && <span className="text-ellipsis">{value}</span>}
    {children}
  </div>
)

const NextRecurringCampaignDateInfoBanner = ({
  recurrenceSettings,
}: {
  recurrenceSettings: IRecurrenceSettings
}) => {
  /*
  Takes the recurrence settings and finds the next scheduled recurring campaign
  while taking deleted recurring campaigns into account
  */
  const timeZone = useSelector(getInstitutionTimeZone)

  const {
    interval,
    startDate,
    endDate,
    changedOccurrences,
  } = recurrenceSettings
  if (!startDate || !endDate || !interval) {
    return null
  }

  const deletedDates = getDeletedDates(changedOccurrences) ?? []
  const momentEndDate = moment.utc(endDate)
  const momentStartDate = moment.utc(startDate)
  const nowUTC = moment().utc()

  // find the first non-deleted recurring campaign instance which is scheduled
  // just after the datetime right now
  const upcomingDates = [
    ...new DateRepeatInterval(
      momentStartDate,
      momentEndDate,
      timeZone,
      interval
    ),
  ]
    .filter(date => {
      const dateNoTime = moment.utc(date).format('YYYY-MM-DD')
      return !deletedDates.some(deletedDate => {
        const deletedDateNoTime = moment.utc(deletedDate).format('YYYY-MM-DD')
        return moment(dateNoTime).isSame(deletedDateNoTime, 'date')
      })
    })
    .filter(date => date.isAfter(nowUTC))
  if (upcomingDates.length === 0) {
    return null
  }

  const nextDate = upcomingDates[0]

  const dateDisplayString = moment
    .tz(nextDate.toDate(), timeZone)
    .format(MOMENT_DATETIME_DISPLAY_FORMAT)
  return (
    <AlertBanner
      className="py-2 pr-4 mb-4 w-100"
      type={AlertType.Info}
      subtitle={`Next scheduled campaign delivery is ${dateDisplayString}`}
    />
  )
}

export const CampaignOverview = ({
  campaignId,
  workflowHumanName,
  date,
  sentToCount,
  isRespondable,
  responded,
  expirationInMins,
  importReportId,
  timeDelay,
  sendCampaignOnce,
  triggerFieldValue,
  recipientLabel,
  recipientsFieldValue,
  filterName,
  pendingUsers,
  appropriateTimeSettings,
  recurring,
  recurrenceSettings,
  isAggregateView,
  contactFilterId,
  region,
}: Pick<
  ICampaign,
  | 'isRespondable'
  | 'workflowHumanName'
  | 'date'
  | 'expirationInMins'
  | 'filterName'
  | 'recipientLabel'
  | 'importReportId'
  | 'pendingUsers'
  | 'recurring'
  | 'contactFilterId'
  | 'region'
> & {
  campaignId: string | undefined
  appropriateTimeSettings: ICampaignSendWindow
  sentToCount: number
  triggerFieldValue?: string
  recipientsFieldValue?: string
  responded: number
  recurrenceSettings: IRecurrenceSettings | null
  isAggregateView?: boolean
  timeDelay?: number
  sendCampaignOnce?: boolean
}) => {
  const dispatch = useDispatch()

  const { hasFeature, FeaturesType } = useFeatures()

  const isAppropriateTime = React.useCallback(
    (settings: ICampaignSendWindow, dateFormat: string) => {
      if (
        isNil(settings.hoursEnd) ||
        isNil(settings.hoursStart) ||
        isNil(settings.minutesEnd) ||
        isNil(settings.minutesStart) ||
        isNil(settings.timezone)
      ) {
        return {
          isAppropriate: true,
          nextAppropriateDate: '',
          nextAppropriateTime: '',
        }
      }

      const now = moment.tz(settings.timezone)
      const start = moment()
        .tz(settings.timezone)
        .set({
          hour: settings.hoursStart,
          minute: settings.minutesStart,
          second: 0,
        })
        .set({ day: now.day() })

      const end = moment()
        .tz(settings.timezone)
        .set({
          hour: settings.hoursEnd,
          minute: settings.minutesEnd,
          second: 0,
        })
        .set({ day: now.day() })

      const nextAppropriateTime = start.format('h:mm a')

      if (now.isBefore(start)) {
        return {
          isAppropriate: false,
          nextAppropriateDate: strftime(dateFormat, new Date()),
          nextAppropriateTime,
        }
      }
      if (now.isBetween(start, end)) {
        return {
          isAppropriate: true,
          nextAppropriateDate: '',
          nextAppropriateTime,
        }
      }
      return {
        isAppropriate: false,
        nextAppropriateDate: strftime(dateFormat, addDays(new Date(), 1)),
        nextAppropriateTime,
      }
    },
    []
  )

  const dateFormat = useSelector(getInstitutionDateFormat)
  const {
    isAppropriate,
    nextAppropriateDate,
    nextAppropriateTime,
  } = isAppropriateTime(appropriateTimeSettings, dateFormat)

  const timeZone = useSelector(getInstitutionTimeZone)

  const [showEditEndDateModal, setShowEditEndDateModal] = React.useState(false)
  const [repeatEndDate, setRepeatEndDate] = React.useState(
    recurrenceSettings?.endDate && !recurrenceSettings?.infinite
      ? new Date(recurrenceSettings.endDate)
      : new Date()
  )
  const [isEndDateInfinite, setIsEndDateInfinite] = React.useState(
    !!recurrenceSettings?.infinite
  )

  const triggerCopy = () => {
    if (triggerFieldValue) {
      return triggerFieldValue
    }
    return moment.tz(date, timeZone).format(MOMENT_DATETIME_DISPLAY_FORMAT)
  }

  const recursCopy = () => {
    if (!recurrenceSettings?.interval) {
      return ''
    }

    const humanizedInterval = humanizedIntervals[recurrenceSettings?.interval]
    if (!recurrenceSettings?.infinite && recurrenceSettings?.endDate) {
      return `${humanizedInterval}, until ${moment
        .tz(new Date(recurrenceSettings?.endDate), timeZone)
        .format(MOMENT_DATE_DISPLAY_FORMAT)}`
    }

    return humanizedInterval
  }

  const timeDelayCopy = () => {
    if (!timeDelay) {
      return ''
    }

    const parsed = secondsToDHMS(timeDelay)

    let copy = ''
    if (parsed.days) {
      copy += `${parsed.days} days `
    }
    if (parsed.hours) {
      copy += `${parsed.hours} hours `
    }
    if (parsed.minutes) {
      copy += `${parsed.minutes} minutes `
    }

    return copy
  }

  const durationCalculation = () => {
    if (expirationInMins != null) {
      const expirationInHours = expirationInMins / 60
      return `${expirationInHours.toFixed()} ${
        expirationInHours > 1 ? 'hrs' : 'hr'
      }`
    }
    return '-'
  }

  return (
    <div className="overview-container border-bottom py-4">
      {showEditEndDateModal && (
        <MainstayModal
          show={showEditEndDateModal}
          text="Change End Date"
          onClose={() => setShowEditEndDateModal(false)}
          onSubmit={() => {
            if (!campaignId) {
              return
            }
            updateRecurringCampaignEndDate(
              campaignId,
              repeatEndDate.toISOString().slice(0, 10),
              isEndDateInfinite
            ).then(res => {
              if (isRight(res)) {
                dispatch(updateRecurringCampaignEndDateAction(res.right))
                setShowEditEndDateModal(false)
                return
              }
              toast.error('Could not update end date')
              setShowEditEndDateModal(false)
            })
          }}
          onCancel={() => setShowEditEndDateModal(false)}
          submitText="Update campaign"
          cancelText="Cancel">
          <RecurringCampaignEndDatePicker
            isInfinite={isEndDateInfinite}
            endDateString={moment(repeatEndDate)
              .tz(timeZone)
              .format('MMMM DD, YYYY')}
            disableDaysBefore={new Date()}
            handleRepeatEndDateChange={date => setRepeatEndDate(date)}
            handleRepeatinfiniteChange={isInfinite =>
              setIsEndDateInfinite(isInfinite)
            }
          />
        </MainstayModal>
      )}
      {pendingUsers > 0 && (
        <AlertBanner
          className="py-2 pr-4 mb-4"
          type={AlertType.Warning}
          subtitle={
            isAppropriate
              ? 'This campaign is still being sent to recipients. This can take several minutes to complete, depending on the size of the audience.'
              : `The local time is outside of the predefined hours for campaign delivery, so delivery has been paused. It will resume on ${nextAppropriateDate} at ${nextAppropriateTime}.`
          }
        />
      )}
      {recurring && recurrenceSettings && (
        <NextRecurringCampaignDateInfoBanner
          recurrenceSettings={recurrenceSettings}
        />
      )}

      <div className="text-mainstay-dark-blue flex-100 mainstay-header-h4-overview mb-3">
        {' '}
        Overview{' '}
      </div>
      <OverviewRow field="Recipients">
        <RecipientGroup
          importReportId={importReportId}
          recipientLabel={recipientLabel}
          filterName={filterName}
          showLink={false}
          className="text-ellipsis"
          contactFilter={contactFilterId}
          recipientsFieldValue={recipientsFieldValue}
        />
      </OverviewRow>
      <OverviewRow
        field="Type"
        value={isRespondable ? 'Interactive' : 'Nudge'}
      />
      <OverviewRow field="Script" value={workflowHumanName} />
      {isAggregateView && recurrenceSettings?.endDate ? (
        <OverviewRow field="Recurs">
          <>
            <span className="text-ellipsis">{recursCopy()}</span>
            <Tooltip content="Change End Date" delay={[400, 0]}>
              <AHIcon
                name="edit"
                onClick={() => setShowEditEndDateModal(true)}
                className={classnames(
                  'pointer med-icon text-mainstay-dark-blue-50 hover-text-mainstay-dark-blue-80'
                )}
              />
            </Tooltip>
          </>
        </OverviewRow>
      ) : (
        <OverviewRow field="Trigger" value={triggerCopy()} />
      )}
      <OverviewRow field="Sent" value={sentToCount} />
      <OverviewRow field="Duration" value={durationCalculation()} />
      {isRespondable && (
        <OverviewRow
          field="Responded"
          value={`${calculateResponsePercentage(sentToCount, responded) ?? 0}%`}
        />
      )}

      {timeDelay && <OverviewRow field="Delay" value={timeDelayCopy()} />}
      {isAggregateView && !isNil(sendCampaignOnce) && (
        <OverviewRow
          field="Send Once Only"
          value={capitalize(String(sendCampaignOnce))}
        />
      )}

      {hasFeature(FeaturesType.PERMS_REGIONS_ENABLED) && (
        <OverviewRow field="User Group" value={region || NATIONAL_USER} />
      )}
    </div>
  )
}

export const CampaignTag = ({
  isOpen = false,
  isIntroductory = false,
  isRecurring = false,
  isDataTriggered = false,
  className,
}: {
  isOpen?: boolean
  isIntroductory?: boolean
  isRecurring?: boolean
  isDataTriggered?: boolean
  className?: string
}) => (
  <div className="d-flex align-items-center">
    {isOpen && <Pill text="open" />}
    {isIntroductory && (
      <Pill
        text="introductory"
        textColor="mainstay-dark-green"
        color="mainstay-dark-mint-w-opacity"
        className={className}
      />
    )}
    {isRecurring && (
      <Pill
        className="mr-2"
        text="recurring"
        color="light-purple"
        textColor="dark-purple"
      />
    )}
    {isDataTriggered && (
      <Pill
        className="mr-2"
        text="data-triggered"
        color="gray-150"
        textColor="charcoal-grey"
      />
    )}
  </div>
)

export const CampaignTitle = ({
  campaign,
  trigger,
  titleSize,
  hideFlag,
}: {
  hideFlag?: boolean
  campaign?: ICampaign | ICampaignByDialogDetails
  trigger?: ICampaignTriggerDetails
  titleSize: number
}) => {
  const data = trigger || campaign

  const campaignExpiration =
    data?.date && data.expirationInMins
      ? addMinutes(data.date, data.expirationInMins)
      : undefined
  const campaignExpirationMoment = moment(campaignExpiration)
  const isOpen =
    campaignExpirationMoment.isValid() && campaignExpirationMoment.isAfter()
  return (
    <div className="pt-2 sticky-title">
      <div className="d-flex align-items-center">
        <h3
          className="text-mainstay-dark-blue mb-0 mr-2"
          style={{ fontSize: titleSize + 'rem' }}>
          {data?.name}
        </h3>{' '}
        <CampaignTag
          isRecurring={data?.recurring}
          isIntroductory={data?.isIntro}
          className="mr-2"
        />
        {campaign && !hideFlag && <CampaignTag isOpen={isOpen} />}
      </div>
    </div>
  )
}

export const CampaignDescription = ({
  campaign,
  descriptionOpacity,
}: {
  campaign: ICampaign | ICampaignTriggerDetails
  descriptionOpacity: number
}) => {
  return (
    <div className="pt-2 pb-2 sticky-description">
      <span className="fs-20px" style={{ opacity: descriptionOpacity }}>
        {campaign.description}
      </span>
    </div>
  )
}

export const generateCampaignReportFileName = (
  campaign: ICampaign | ICampaignTriggerDetails | ICampaignByDialogDetails
) => {
  return generateReportFileName(
    `${campaign.name.substring(0, 16)}_${(campaign.filterName ?? '').substring(
      0,
      16
    )}`
  )
}

export const CampaignCreationInfo = ({
  creationInfo,
}: {
  creationInfo: ICreationInfo
}) => {
  const timeZone = useSelector(getInstitutionTimeZone)
  const { firstName, lastName, createdAt } = creationInfo
  if (!firstName || !lastName || !createdAt) {
    return null
  }

  const dateDisplayString = moment
    .tz(new Date(createdAt), timeZone)
    .format(MOMENT_DATETIME_DISPLAY_FORMAT)
  return (
    <div>
      <span className="text-mainstay-dark-blue-80 fs-0_8rem">
        Campaign created by {`${firstName} ${lastName}`}, {dateDisplayString}
      </span>
    </div>
  )
}

export const CampaignAudienceFilter = ({
  audienceFromQuery,
}: {
  audienceFromQuery?: ParsedQuery
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [showModal, setShowModal] = React.useState(false)
  const [selectedAudience, setSelectedAudience] = React.useState<
    ISegmentOption | undefined
  >({
    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    label: (audienceFromQuery?.audienceName as string) || '',
    name: (audienceFromQuery?.audienceName as string) || '',
    value: audienceFromQuery?.audience
      ? Number(audienceFromQuery.audience)
      : undefined,
  })

  const [chipDisplayAudience, setChipDisplayAudience] = React.useState<
    ISegmentOption | undefined
  >(selectedAudience)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const handleSubmit = () => {
    setChipDisplayAudience(selectedAudience)
    const filters = updateQueryString(
      {
        audience: selectedAudience?.value,
        audienceName: selectedAudience?.name,
      },
      location.search
    )

    // Update query parameters which triggers the useEffect request to getCampaignDetails in the parent component
    dispatch(push({ search: filters }))

    toggleModal()
  }

  const handleAudienceChange = (option?: ISegmentOption) => {
    if (option?.value) {
      setSelectedAudience(option)
    }
  }

  const onFilterRemove = () => {
    setSelectedAudience(undefined)
    const filters = updateQueryString(
      {
        audience: undefined,
        audienceName: undefined,
      },
      location.search
    )

    // Update query parameters which triggers the useEffect request to getCampaignDetails in the parent component
    dispatch(push({ search: filters }))
  }

  return (
    <>
      <div className="d-flex">
        <button className="btn mr-2" onClick={toggleModal}>
          <FilterIcon />
        </button>
        {chipDisplayAudience?.value && chipDisplayAudience?.label && (
          <Chip
            key={chipDisplayAudience.value}
            label={chipDisplayAudience.label}
            type="clear"
            onClear={onFilterRemove}
          />
        )}
      </div>

      <MainstayModal
        show={showModal}
        onClose={toggleModal}
        text="Filters"
        submitText="Apply Filter"
        cancelText="Close"
        onSubmit={handleSubmit}
        cancelTrackingEvent={{
          location: 'campaigns',
          action: 'click',
          object: 'close filter',
        }}>
        <>
          <div className="filter-modal-sub-header pb-2">Audience</div>
          <ReadOnlyContactSegmentSelect
            selectedFilterId={
              selectedAudience?.value
                ? Number(selectedAudience.value)
                : undefined
            }
            onNewSegment={noOp}
            placeholder="Select audience to filter campaign report details by"
            showCreateNewButton={false}
            onChange={handleAudienceChange}
            isClearable={true}
            onCancel={() => setSelectedAudience(undefined)}
          />
        </>
      </MainstayModal>
    </>
  )
}

const CampaignDetailsPage = ({
  isRecurringInstance,
}: ICampaignDetailsPageProps) => {
  const { hasPermission } = usePermissionsContext()

  const { id } = useParams<{ id: string }>()
  const campaign = useSelector(getSelectedCampaign)
  const initialStateId = useSelector(getWorkflowInitialState)
  const results = useSelector(getResults)
  const aggregateResults = useSelector(getAggregateResults)
  const globalAppropriateTimeSettings = useSelector(
    getGlobalAppropriateTimeSettings
  )
  const campaignImportData = useSelector(getCampaignImportDetails)

  const [descriptionOpacity, setDescriptionOpacity] = React.useState(1.0)
  const [titleSize, setTitleSize] = React.useState(TITLE_SIZE_START)

  const ref = React.useRef<HTMLDivElement | null>(null)

  const scrollToTop = React.useCallback(() => {
    ref.current?.scrollIntoView()
    setTitleSize(TITLE_SIZE_START)
    setDescriptionOpacity(1.0)
  }, [ref])

  const location = useLocation()
  const queryFilters = getQueryFilters(location)
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  const audience = queryFilters.audience as string

  const dispatch = useDispatch()
  const redirectOnAudienceFailure = React.useCallback(() => {
    dispatch(push({ search: '' }))
  }, [dispatch])

  React.useEffect(() => {
    // if recurring, also fetch aggregate recurring campaign details
    if (isRecurringInstance) {
      fetchRecurringCampaignDetailsAsync(id, true)(dispatch)
    } else {
      fetchCampaignDetailsAsync(
        id,
        audience,
        redirectOnAudienceFailure
      )(dispatch)
    }
    scrollToTop()
  }, [
    id,
    isRecurringInstance,
    redirectOnAudienceFailure,
    audience,
    dispatch,
    scrollToTop,
  ])

  const links = useGetCampaignSidebarLinks({
    campaignSidebarFilter: NO_FILTER,
  })

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    // Get the current scroll amount (in pixels) of our div
    const scrollTop = event.currentTarget.scrollTop

    // Scale the opacity of the description and the size of the title based on the scroll amount
    const opacityPercent =
      (scrollTop - DESCRIPTION_FADE_START) /
      (DESCRIPTION_FADE_END - DESCRIPTION_FADE_START)
    const titleSizePercent =
      (scrollTop - TITLE_RESIZE_START) / (TITLE_RESIZE_END - TITLE_RESIZE_START)

    setDescriptionOpacity(1.0 - clamp(opacityPercent, 0.0, 1.0))
    setTitleSize(
      clamp(titleSizePercent, 0.0, 1.0) * (TITLE_SIZE_END - TITLE_SIZE_START) +
        TITLE_SIZE_START
    )
  }

  if (isFailure(campaign)) {
    return (
      <CampaignContainer links={links}>
        <FailureFetchingCampaign
          error={
            isFailure(campaign)
              ? campaign.failure
              : 'Error fetching campaign details'
          }
        />
      </CampaignContainer>
    )
  }

  if (!isSuccess(campaign)) {
    return (
      <CampaignContainer links={links}>
        <CenteredLoader className="w-100" />
      </CampaignContainer>
    )
  }

  const campaignReportTab = (): ITabbedViewOption[] => {
    if (campaign.data.recurring && !isRecurringInstance) {
      return []
    }
    return [
      {
        label: 'Campaign Report',
        hashParam: 'report',
        eventLocation: 'campaigns',
        eventAction: 'click',
        eventObject: 'report',
        component: (
          <>
            {!campaign.data.recurring && (
              <CampaignAudienceFilter audienceFromQuery={queryFilters} />
            )}
            <CampaignOverview
              campaignId={campaign.data.id}
              workflowHumanName={campaign.data.workflowHumanName}
              isRespondable={campaign.data.isRespondable}
              date={campaign.data.date}
              responded={campaign.data.countDistinctUsersResponded || 0}
              sentToCount={
                campaign.data.engagementData.countEligibleUsersProcessed
              }
              importReportId={campaign.data.importReportId}
              recipientLabel={campaign.data.recipientLabel}
              filterName={campaign.data.filterName}
              expirationInMins={campaign.data.expirationInMins}
              appropriateTimeSettings={globalAppropriateTimeSettings}
              pendingUsers={campaign.data.pendingUsers}
              recurring={campaign.data.recurring}
              recurrenceSettings={campaign.data.recurrenceSettings ?? null}
              contactFilterId={campaign.data.contactFilterId}
              region={campaign.data.region}
            />
            <div className="pt-4 pb-0 border-bottom">
              <CampaignEngagementSection
                key="campaignEngagemnt"
                campaignId={id}
                isRecurring={isRecurringInstance}
                isAggregate={false}
                audience={audience}
                engagementData={campaign.data.engagementData}
                reportFileName={generateCampaignReportFileName(campaign.data)}
                generateCampaignReport={({
                  preferences,
                  reportFileName,
                  workflowResponses,
                  campaignReportType,
                  onDownloadReportComplete,
                  audience,
                }) =>
                  generateCampaignReportAsync({
                    campaignId: id,
                    preferences,
                    reportFileName,
                    workflowResponses,
                    campaignReportType,
                    onDownloadReportComplete,
                    isRecurring: isRecurringInstance,
                    isAggregate: false,
                    audience,
                  })(dispatch)
                }
                isRespondable={campaign.data.isRespondable}
                countCampaignsSent={undefined}
              />
            </div>
            <div className="py-4">
              <CampaignResultsCard
                key="campaignResults"
                reportFileName={generateCampaignReportFileName(campaign.data)}
                campaignId={id}
                workflowSteps={results}
                isRespondable={campaign.data.isRespondable}
                isRecurring={isRecurringInstance}
                isAggregate={false}
                audience={audience}
                generateCampaignReport={({
                  preferences,
                  reportFileName,
                  workflowResponses,
                  campaignReportType,
                  onDownloadReportComplete,
                  audience,
                }) =>
                  generateCampaignReportAsync({
                    campaignId: id,
                    preferences,
                    reportFileName,
                    workflowResponses,
                    campaignReportType,
                    onDownloadReportComplete,
                    isRecurring: isRecurringInstance,
                    isAggregate: false,
                    audience,
                  })(dispatch)
                }
              />
              <CampaignCreationInfo creationInfo={campaign.data.creationInfo} />
            </div>
          </>
        ),
        className: 'px-0',
      },
    ]
  }

  const aggregateReportTab = (): ITabbedViewOption[] => {
    if (!campaign.data.recurring || !campaign.data.aggregateData) {
      return []
    }
    return [
      {
        label: 'Aggregate Report',
        hashParam: 'aggregate',
        eventLocation: 'campaigns',
        eventAction: 'click',
        eventObject: 'aggregate',
        component: (
          <>
            <CampaignOverview
              campaignId={campaign.data.id}
              workflowHumanName={campaign.data.workflowHumanName}
              isRespondable={campaign.data.isRespondable}
              date={campaign.data.date}
              responded={campaign.data.aggregateData.totalDistinctResponses}
              sentToCount={
                campaign.data.aggregateData.countEligibleUsersProcessed
              }
              sendCampaignOnce={campaign.data.sendCampaignOnce}
              importReportId={campaign.data.importReportId}
              recipientLabel={campaign.data.recipientLabel}
              filterName={campaign.data.filterName}
              contactFilterId={campaign.data.contactFilterId}
              expirationInMins={campaign.data.expirationInMins}
              appropriateTimeSettings={globalAppropriateTimeSettings}
              pendingUsers={campaign.data.pendingUsers}
              recurring={campaign.data.recurring}
              recurrenceSettings={campaign.data.recurrenceSettings ?? null}
              isAggregateView={true}
              region={campaign.data.region}
            />
            <div className="pt-4 pb-0 border-bottom">
              {campaign.data.aggregateData && (
                <CampaignEngagementSection
                  key="aggregateEngagemnt"
                  campaignId={id}
                  isRecurring={isRecurringInstance}
                  isAggregate={true}
                  engagementData={campaign.data.aggregateData}
                  countCampaignsSent={
                    campaign.data.aggregateData.countCampaignsSent
                  }
                  generateCampaignReport={({
                    preferences,
                    reportFileName,
                    workflowResponses,
                    campaignReportType,
                    onDownloadReportComplete,
                  }) =>
                    generateCampaignReportAsync({
                      campaignId: id,
                      preferences,
                      reportFileName,
                      workflowResponses,
                      campaignReportType,
                      onDownloadReportComplete,
                      isRecurring: isRecurringInstance,
                      isAggregate: true,
                    })(dispatch)
                  }
                  isRespondable={campaign.data.isRespondable}
                />
              )}
            </div>
            <div className="py-4 border-bottom">
              {campaign.data.aggregateData && (
                <CampaignResultsCard
                  key="aggregateResults"
                  reportFileName={generateCampaignReportFileName(campaign.data)}
                  campaignId={id}
                  workflowSteps={aggregateResults}
                  isRespondable={campaign.data.isRespondable}
                  isRecurring={isRecurringInstance}
                  isAggregate={true}
                  countCampaignsSent={
                    campaign.data.aggregateData.countCampaignsSent
                  }
                  generateCampaignReport={({
                    preferences,
                    reportFileName,
                    workflowResponses,
                    campaignReportType,
                    onDownloadReportComplete,
                  }) =>
                    generateCampaignReportAsync({
                      campaignId: id,
                      preferences,
                      reportFileName,
                      workflowResponses,
                      campaignReportType,
                      onDownloadReportComplete,
                      isRecurring: isRecurringInstance,
                      isAggregate: true,
                    })(dispatch)
                  }
                />
              )}
              <div className="py-4">
                {campaign.data.aggregateData && (
                  <CampaignHistorySection
                    parentId={campaign.data.aggregateData.parentId}
                    countCampaignsSent={
                      campaign.data.aggregateData.countCampaignsSent
                    }
                    totalRecipients={
                      campaign.data.aggregateData.totalRecipients
                    }
                    startDate={campaign.data.aggregateData.startDate}
                    isInteractive={campaign.data.isRespondable}
                  />
                )}
              </div>

              <CampaignCreationInfo creationInfo={campaign.data.creationInfo} />
            </div>
          </>
        ),
      },
    ]
  }

  const tabbedViewOptions = [...campaignReportTab(), ...aggregateReportTab()]

  if (hasPermission(PERMISSIONS.SCRIPT.VIEW)) {
    tabbedViewOptions.push({
      label: 'Script',
      hashParam: 'script',
      eventLocation: 'campaigns',
      eventAction: 'click',
      eventObject: 'script',
      component: (
        <>
          {campaign && initialStateId ? (
            <CampaignPreviewCard
              scriptId={campaign.data.scriptId}
              campaignReview={true}
              initialStateId={initialStateId}
              isArchived={campaign.data.scriptHidden}
              workflowHumanName={campaign.data.workflowHumanName}
              /* For campaigns that do not require `results` for the Campaign Report, we skip  *
               * that fetch to optimize. In these cases, we should use aggregate steps.        */
              useAggregateSteps={campaign.data.recurring}
            />
          ) : null}
        </>
      ),
    })
  }

  if (hasPermission(PERMISSIONS.AUDIENCE.VIEW)) {
    tabbedViewOptions.push({
      label: 'Recipients',
      hashParam: 'recipients',
      eventLocation: 'campaigns',
      eventAction: 'click',
      eventObject: 'recipients',
      component:
        campaign.data.contactFilterId && campaign.data.filterName ? (
          <CampaignAudienceSection
            campaignId={id}
            contactFilterId={campaign.data.contactFilterId}
            contactFilterName={campaign.data.filterName}
            scheduledAt={campaign.data.date}
            isRecurring={isRecurringInstance}
          />
        ) : (
          <CampaignImportSection
            campaignImportData={campaignImportData}
            fetchCampaignImportDetails={() =>
              fetchCampaignImportDetailsAsync(id)(dispatch)
            }
            campaignId={id}
          />
        ),
    })
  }

  return (
    <CampaignContainer
      passStyleToChild
      links={links}
      contentClassName="d-flex h-100">
      <div
        className="w-100 d-flex flex-column campaign-container-scroll pt-0"
        onScroll={scrollHandler}>
        <AdmithubOnlyButton
          allowEngineeringOnly
          onClick={() =>
            rescheduleFailedCampaign(campaign.data.id).then(res => {
              if (isLeft(res)) {
                toast.error('Failed to reschedule campaign.')
                return
              }
              toast.success('Campaign has been rescheduled.')
            })
          }
          className="rescheduler-btn">
          {' '}
          Reschedule Campaign{' '}
        </AdmithubOnlyButton>
        <div className="pt-2" ref={ref} />
        <CampaignTitle campaign={campaign.data} titleSize={titleSize} />
        <CampaignDescription
          campaign={campaign.data}
          descriptionOpacity={descriptionOpacity}
        />
        <TabbedView
          className="campaign-tabs-scroll"
          tabContentClassName="px-0"
          tabCollectionClassName="px-0 sticky-tabs"
          tabClassName="py-2 mr-3"
          options={tabbedViewOptions}
        />
      </div>
    </CampaignContainer>
  )
}

export default CampaignDetailsPage
