import * as React from 'react'
import NavBarContainer from 'page/NavBarPage'
import {
  Calendar as BigCalendar,
  EventProps,
  momentLocalizer,
  NavigateAction,
} from 'react-big-calendar'
import moment from 'moment-timezone'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// (jerardi) Important for this to come after the react-big-calendar.css file for cascade to work
import 'page/calendar/calendar.scss'
import { isLeft, isRight } from 'fp-ts/lib/These'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import classNames from 'classnames'
import {
  DeleteRecurringCampaignTypeEnum,
  IScheduledCampaign,
} from 'store/scheduledCampaigns/reducer'
import { CalendarPopoverContainer } from 'components/CalendarPopover/CalendarPopover'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { ColoredCheckboxCollection } from 'components/ColoredCheckboxCollection/ColoredCheckboxCollection'
import { Manager, Popper } from 'react-popper'
import { ReferenceObject } from 'popper.js'
import { VirtualReference } from 'components/PopoverComponent/utils'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { Button } from 'components/Button/Button'
import PermissionGuard from 'util/permissions/PermissionGuard'
import { PERMISSIONS } from 'util/permissions/permissions'
import { DateRepeatInterval, RepeatInterval } from 'util/dateRepeatIntervals'
import format from 'date-fns/format'
import { QUERY_PARAM_DATE_FORMAT } from 'components/FilterContactsModal/FilterContactsModal'
import {
  ChangedOccurrenceTypeEnum,
  IChangedOccurrence,
} from 'store/campaign-scheduler/reducer'
import { ConfirmationModal } from 'components/Modal/Modal'
import { CreateButton } from 'components/PageHeader/PageHeader'
import {
  ChevronIcon,
  MainstayPage,
} from 'components/MainstayPageContainer/MainstayPageContainer'
import { addMinutes } from 'date-fns'
import { useHistory } from 'react-router'
import * as api from 'api'
import { appendQueryFilter, getQueryFilters } from 'util/queryFilters'
import { months } from 'util/datetime'
import { PopoverComponent } from 'components/PopoverComponent/PopoverComponent'
import { isDate } from 'lodash'
import { toastOnHttpError500or400 } from 'api/http'

const localizer = momentLocalizer(moment)

interface ICampaignScheduleToolbarProps {
  label: string
  onNavigate: (navigate: NavigateAction, date?: Date) => void
  now: Date
  monthYearOverlayVisible: boolean
  updateOverlayVisible: (show: boolean) => void
}

export const getDeletedDates = (
  changedOccurrences: IChangedOccurrence[] | undefined
) =>
  changedOccurrences
    ?.filter(o => o.type === ChangedOccurrenceTypeEnum.deleted)
    .reduce((acc: moment.Moment[], curr: IChangedOccurrence) => {
      return [...acc, moment.utc(curr.date)]
    }, [])

const MonthRow = ({
  months,
  setMonth,
  onNavigate,
  year,
  selectedMonth,
}: {
  months: string[]
  year: number
  setMonth: (month: string) => void
  onNavigate: (navigate: NavigateAction, date?: Date) => void
  selectedMonth: string
}) => {
  return (
    <div className="row month-row py-1">
      {months.slice(0, 4).map(month => {
        const monthIsSelected = month.toLowerCase() === selectedMonth
        return (
          <Button
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setMonth(month)
              onNavigate('DATE', new Date(`${month} 1, ${year}`))
            }}
            className={classNames(
              {
                'bg-mainstay-focused-gray': monthIsSelected,
                'bg-transparent': !monthIsSelected,
              },
              'month-button hover-bg-mainstay-dark-blue-10 date-selector-overlay-month'
            )}
            key={month}>
            {month}
          </Button>
        )
      })}
    </div>
  )
}

const DateSelector = ({
  now,
  onNavigate,
}: {
  now: Date
  onNavigate: (navigate: NavigateAction, date?: Date) => void
}) => {
  const [year, setYear] = React.useState<number>(now.getFullYear())
  const [month, setMonth] = React.useState<string>(months[now.getMonth()])

  const handleUpdateYear = (num: number) => {
    const newYear = year + num
    setYear(newYear)
    onNavigate('DATE', new Date(`${month} 1, ${newYear}`))
  }

  return (
    <div className="date-selector-overlay">
      <div className="date-selector-overlay-header mainstay-header-h4 mb-0">
        <Button
          className="bg-transparent"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            handleUpdateYear(-1)
          }}>
          <ChevronIcon transform="scale(-1, 1)" />
        </Button>
        <div className="mx-2 date-selector-header-year">{year.toString()}</div>
        <Button
          className="bg-transparent"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            handleUpdateYear(1)
          }}>
          <ChevronIcon />
        </Button>
      </div>
      <div className="mt-0 date-selector-overlay-months container">
        <MonthRow
          months={months.slice(0, 4)}
          year={year}
          setMonth={setMonth}
          onNavigate={onNavigate}
          selectedMonth={month.toLowerCase()}
        />
        <MonthRow
          months={months.slice(4, 8)}
          year={year}
          setMonth={setMonth}
          onNavigate={onNavigate}
          selectedMonth={month.toLowerCase()}
        />
        <MonthRow
          months={months.slice(8)}
          year={year}
          setMonth={setMonth}
          onNavigate={onNavigate}
          selectedMonth={month.toLowerCase()}
        />
      </div>
    </div>
  )
}

const DateButton = ({
  label,
  toggleOverlayVisible,
}: {
  label: string
  toggleOverlayVisible: () => void
}) => (
  <Button
    className="m-0 py-1 pl-0 pr-4 bg-transparent hover-bg-mainstay-focused-gray text-mainstay-dark-blue position-relative"
    onClick={toggleOverlayVisible}>
    <div className="m-0 py-0 campaign-calendar-month-header">
      <div className="mainstay-header-h4 py-0 my-0">{label}</div>
      <div className="fs-24px hide-outline month-header-dropdown position-absolute pl-5">
        <AHIcon name="arrow_drop_down" className="hide-outline" />
      </div>
    </div>
  </Button>
)

function CampaignScheduleToolbar({
  label,
  onNavigate,
  now,
  monthYearOverlayVisible,
  updateOverlayVisible,
}: ICampaignScheduleToolbarProps) {
  const history = useHistory()

  return (
    <div className="w-100">
      <div className="d-flex align-items-center justify-content-between py-4">
        <div className="d-flex align-items-center">
          <Button
            className="text-mainstay-dark-blue-65 border-mainstay-dark-blue-65 bg-white "
            onClick={() => onNavigate('TODAY')}>
            Today
          </Button>
        </div>
        <PopoverComponent
          onClickOutside={() => updateOverlayVisible(false)}
          positionFixed={true}
          visible={monthYearOverlayVisible}
          popoverPlacement="bottom"
          renderReference={() => (
            <DateButton
              label={label}
              toggleOverlayVisible={() =>
                updateOverlayVisible(!monthYearOverlayVisible)
              }
            />
          )}
          renderPopper={() => (
            <DateSelector now={now} onNavigate={onNavigate} />
          )}
        />
        <PermissionGuard
          className="align-items-center"
          permission={PERMISSIONS.CAMPAIGN.CREATE}>
          <CreateButton
            title="Create Campaign"
            onClick={() => history.push('/schedule-campaign')}
            eventAction="click"
            eventObject="new campaign button"
            outlined
          />
        </PermissionGuard>
      </div>
    </div>
  )
}

export const RoundedCircle = ({
  size,
  color,
}: {
  size: number
  color: string
}) => {
  return (
    <span
      className="mr-1 rounded-circle flex-shrink-0"
      style={{
        height: size,
        width: size,
        backgroundColor: color,
      }}
    />
  )
}

interface ICalEventProps {
  event?: {
    event: string
    title: string
    color: string
  }
}

const CampaignScheduleMonthEvent: React.FunctionComponent<EventProps<
  IModalState
>> = props => {
  const title = props.event ? props.event.title : ''
  return (
    <div className="d-flex align-items-center ml-1">
      <RoundedCircle size={10} color={props.event ? props.event.color : ''} />
      <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {title}
      </span>
    </div>
  )
}

const dateFormats = {
  dateFormat: 'D',
}

export interface IModalState {
  region: string
  start: Date | null
  end: Date | null
  occurrenceDate?: Date
  completedRecurringCampaigns: {
    [date: string]: string
  }
  id: string
  title: string
  on: string
  description: string
  recipientLabel: string | null
  importReportId: string | null
  deleting: boolean
  readonly started: boolean | null
  hasPermissionToDelete: boolean
  regionDisplayName?: string
  color: string
  workflowHumanName: string
  filterName: string | null
  recurring: boolean
  event?: ICalEventProps
  contactFilter?: number | undefined
  campaign?: string
}

interface ICalendarState {
  show: boolean
  showDeletionModal: boolean
  showTimeChangeModal: boolean
  eventVisibilityByRegionId: { [regionId: string]: boolean }
  date: Date
  modal: IModalState
  eventPopoverRef: null | ReferenceObject
  onConfirmDelete: undefined | (() => Promise<void>)
  onConfirmReschedule: undefined | (() => void)
}

interface ICalendarProps {
  scheduledCampaigns: Array<
    IScheduledCampaign & { color: string; regionDisplayName: string }
  >
  institutionTimeZone: string
  fetchData: (start: string, end: string) => Promise<void>
  fetchCurrentInstitution: () => Promise<void>
  remove: (
    id: string,
    type?: DeleteRecurringCampaignTypeEnum,
    date?: string
  ) => Promise<void>
  isLoading: boolean
  regionFilteringEnabled: boolean
  userGroups: string[] | null
  regionFilterOptions: { id: string; displayName: string; color: string }[]
}

const SidebarRegions = ({
  regions,
}: {
  readonly regions: {
    readonly items: {
      readonly id: string
      readonly name: string
      readonly label: string
      readonly color: string
      readonly checked: boolean
    }[]
    readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
}) => (
  <>
    <h6 className="text-mainstay-dark-blue-65 fw-700 pt-3 pl-4">REGIONS</h6>
    <div className="pt-2 pl-3">
      <ColoredCheckboxCollection
        items={regions.items}
        onChange={regions.onChange}
      />
    </div>
  </>
)

const YEAR_REGEX = /^\d{4}$/g

// TODO(sbdchd): this setup for state could use a refactor to distinguished
// between being unset
export const Calendar = ({
  scheduledCampaigns,
  institutionTimeZone,
  fetchData,
  fetchCurrentInstitution,
  remove,
  isLoading,
  regionFilteringEnabled,
  userGroups,
  regionFilterOptions,
}: ICalendarProps) => {
  const history = useHistory()

  const getDate = React.useCallback(() => {
    let dateToUse = new Date()
    const params = getQueryFilters(window.location)
    const year = params['year']?.toString()
    const month = params['month']?.toString()
    let monthToUse = months[dateToUse.getMonth()]
    let yearToUse = dateToUse.getFullYear().toString()

    if (year && year.match(YEAR_REGEX)) {
      yearToUse = year
    }

    if (
      month &&
      months.map(elem => elem.toLowerCase()).includes(month.toLowerCase())
    ) {
      monthToUse = month
    }
    const date = new Date(`${monthToUse} 1, ${yearToUse}`)
    if (isDate(date)) {
      dateToUse = date
    }

    return dateToUse
  }, [])

  const [isFirstLoad, setIsFirstLoad] = React.useState(true)
  const initialState: ICalendarState = {
    show: false,
    showDeletionModal: false,
    showTimeChangeModal: false,
    eventVisibilityByRegionId: {},
    date: getDate(),
    modal: {
      region: '',
      id: '',
      title: '',
      description: '',
      on: '',
      deleting: false,
      recipientLabel: null,
      importReportId: null,
      started: false,
      hasPermissionToDelete: false,
      workflowHumanName: '',
      color: '',
      filterName: null,
      start: null,
      end: null,
      recurring: false,
      event: undefined,
      contactFilter: undefined,
      campaign: '',
      completedRecurringCampaigns: {},
    },
    onConfirmDelete: undefined,
    onConfirmReschedule: undefined,
    eventPopoverRef: null,
  }

  const [state, setState] = React.useState<ICalendarState>(initialState)
  const [monthYearOverlayVisible, setMonthYearOverlayVisible] = React.useState<
    boolean
  >(false)

  const handleFetchData = React.useCallback(
    (date?: Date) => {
      // we need to fetch the first sunday of the first week, and
      // the last saturday. This means we will fetch some of the last and next
      // months. Instead of being super precise we just fetch +/- 6 days, which
      // works but might fetch an extra couple of days (not a big deal).
      const startOfMonth = moment(date)
        .startOf('month')
        .subtract(6, 'days')
        .format()
      const endOfMonth = moment(date)
        .endOf('month')
        .add(6, 'days')
        .format()
      return fetchData(startOfMonth, endOfMonth)
    },
    [fetchData]
  )

  React.useEffect(() => {
    setState(state => ({ ...state, date: getDate() }))
  }, [getDate, history.location])

  React.useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
      handleFetchData(state.date)
      fetchCurrentInstitution()
    }
  }, [fetchCurrentInstitution, handleFetchData, state.date, isFirstLoad])

  React.useEffect(() => {
    const baseObj: { [regionId: string]: boolean } = {}
    setState(state => ({
      ...state,
      eventVisibilityByRegionId: regionFilterOptions.reduce((acc, curr) => {
        acc[curr.id] = true
        return acc
      }, baseObj),
    }))
  }, [regionFilterOptions])

  const toggleRegionVisibility = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const checked = e.target.checked
    setState(s => ({
      ...s,
      eventVisibilityByRegionId: {
        ...s.eventVisibilityByRegionId,
        [name]: checked,
      },
    }))
  }

  const handleClose = () => {
    setState({ ...state, show: false })
  }

  const handleDelete = async (
    deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
    occurrenceDate?: Date
  ) => {
    setState(prev => ({
      ...prev,
      modal: { ...prev.modal, deleting: true },
    }))
    try {
      if (deleteRecurringCampaignType && occurrenceDate) {
        await remove(
          state.modal.id,
          deleteRecurringCampaignType,
          format(
            addMinutes(occurrenceDate, occurrenceDate.getTimezoneOffset()),
            QUERY_PARAM_DATE_FORMAT
          )
        )
      } else {
        await remove(state.modal.id)
      }
      handleClose()
    } catch (e) {
      // we handle this error in redux, but propagate it up so that we don't
      // close the modal on error.
    }

    setState(prev => ({
      ...prev,
      modal: { ...prev.modal, deleting: false },
      eventPopoverRef: null,
      showDeletionModal: false,
      onConfirmDelete: undefined,
    }))
  }

  const handleRescheduleCampaign = async (date: string) => {
    try {
      const res = await api.updateScheduledMessageDate(state.modal.id, date)

      if (isRight(res)) {
        toast.success('Campaign rescheduled.')
        handleFetchData(state.date)
      }

      if (isLeft(res)) {
        if (res.left.kind === 'http') {
          toastOnHttpError500or400(res.left.http)
        } else {
          toast.error('Failed to reschedule campaign.')
        }
      }
    } catch (e) {
      toast.error('Failed to reschedule campaign.')
    } finally {
      handleClose()
    }
  }

  const handleEventSelect = (
    calendarEvent: IModalState,
    e: React.SyntheticEvent<HTMLElement>
  ) => {
    e.stopPropagation()
    const rect = e.currentTarget.getBoundingClientRect()

    setState({
      ...state,
      show: true,
      modal: calendarEvent,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      eventPopoverRef: (new VirtualReference(
        rect
      ) as unknown) as ReferenceObject,
    })
  }

  const handleOnNav = (date: Date) => {
    history.push(
      appendQueryFilter(window.location, 'year', date.getFullYear().toString())
    )
    history.push(
      appendQueryFilter(
        window.location,
        'month',
        months[date.getMonth()].toLowerCase()
      )
    )
    setState({ ...state, date })
    handleFetchData(date)
  }

  const parseRecurringCampaignRange = (
    start: moment.Moment,
    end: moment.Moment,
    interval: RepeatInterval,
    institutionTimeZone: string,
    deletedDates?: moment.Moment[]
  ): moment.Moment[] => {
    let range = [
      ...new DateRepeatInterval(start, end, institutionTimeZone, interval),
    ]

    // filter dates to include "calendar month" dates (with a few trailing days at from previous and next months)
    const startOfMonth = moment(state.date)
      .startOf('month')
      .subtract(6, 'days')
      .format()
    const endOfMonth = moment(state.date)
      .endOf('month')
      .add(6, 'days')
      .format()

    range = range.filter(date => date.isBetween(startOfMonth, endOfMonth))

    // filter out any date whose occurrence has been deleted
    if (deletedDates) {
      range = range.filter(date => {
        const dateNoTime = moment.utc(date).format('YYYY-MM-DD')
        return deletedDates.every(deletedDate => {
          const deletedDateNoTime = moment.utc(deletedDate).format('YYYY-MM-DD')
          return !moment(dateNoTime).isSame(deletedDateNoTime, 'date')
        })
      })
    }
    return range
  }

  const formatEvent = (
    e: IScheduledCampaign & { color: string; regionDisplayName: string },
    date?: Date
  ): IModalState => {
    return {
      ...e,
      start: date || new Date(e.on),
      end: date || new Date(e.on),
      occurrenceDate: date || new Date(e.on),
      title: e.name,
      deleting: !!e.deleting,
      hasPermissionToDelete: !userGroups || userGroups?.includes(e.region),
      completedRecurringCampaigns: e.completedRecurringCampaigns,
    }
  }
  const visibleEvents = (): IModalState[] => {
    let events: IModalState[] = []
    scheduledCampaigns.forEach(x => {
      if (x.recurring) {
        const {
          recurrenceSettings: {
            startDate,
            endDate,
            interval,
            changedOccurrences,
          },
        } = x
        const deletedDates = getDeletedDates(changedOccurrences)

        if (!startDate || !endDate || !interval) {
          return
        }
        const momentStartDate = moment.utc(startDate)
        const momentEndDate = moment.utc(endDate)
        // If the user has deleted all of the recurring campaign occurrences via
        // the "this and all future occurrences" option, we need to make the remaining event not render
        // on the initial redux non-hard-reload refresh
        if (momentEndDate.isBefore(momentStartDate, 'day')) {
          return
        }

        /**
         * To avoid locking up the calendar page with recurring campaigns that are infinite or go far into the future,
         * process 2 month's worth of events based on the current month/date being viewed.
         * If the end date of the recurring campaign is within the current month, or the month after,
         * either via deleting all future occurrences or otherwise,
         * process the events only up to the end date of the recurring campaign.
         */
        let offsetEndDate = momentEndDate
        const offsetFromStateDate = moment.utc(state.date).add(2, 'month')
        if (!momentEndDate.isBefore(offsetFromStateDate)) {
          offsetEndDate = offsetFromStateDate
        }

        const range = parseRecurringCampaignRange(
          momentStartDate,
          offsetEndDate,
          interval,
          institutionTimeZone,
          deletedDates
        )
        range.forEach(date => {
          const event = formatEvent(x, date.toDate())
          events.push(event)
        })
      } else {
        const startOfMonth = moment(state.date)
          .startOf('month')
          .subtract(6, 'days')
          .format()
        const endOfMonth = moment(state.date)
          .endOf('month')
          .add(6, 'days')
          .format()
        if (x.on >= startOfMonth && x.on <= endOfMonth) {
          const event = formatEvent(x)
          events.push(event)
        }
      }
    })

    if (regionFilteringEnabled) {
      events = events.filter(
        x => x.region && state.eventVisibilityByRegionId[x.region]
      )
    }

    return events
  }

  const regionFilterCheckboxItems = () => {
    return regionFilterOptions.map(o => {
      return {
        id: o.id,
        name: o.id,
        label: o.displayName,
        color: o.color,
        checked: state.eventVisibilityByRegionId[o.id],
      }
    })
  }

  return (
    <NavBarContainer
      title="Calendar"
      className="d-flex h-100"
      pageMainClassName="page-calendar">
      <MainstayPage
        header="Calendar"
        sidebarContent={
          <div>
            {regionFilteringEnabled && (
              <SidebarRegions
                regions={
                  regionFilteringEnabled && {
                    items: regionFilterCheckboxItems(),
                    onChange: toggleRegionVisibility,
                  }
                }
              />
            )}
          </div>
        }
        pageContentClassName="h-100">
        <BigCalendar
          localizer={localizer}
          views={['month']}
          className={classNames({
            'calendar-loading': isLoading,
          })}
          popup
          onSelectEvent={handleEventSelect}
          onNavigate={handleOnNav}
          components={{
            toolbar: ({ onNavigate }) => (
              <CampaignScheduleToolbar
                onNavigate={onNavigate}
                label={`${
                  months[state.date.getMonth()]
                } ${state.date.getFullYear()}`}
                now={state.date}
                monthYearOverlayVisible={monthYearOverlayVisible}
                updateOverlayVisible={show => setMonthYearOverlayVisible(show)}
              />
            ),
            month: {
              event: CampaignScheduleMonthEvent,
            },
          }}
          defaultDate={state.date}
          date={state.date}
          events={visibleEvents()}
          formats={dateFormats}
        />
      </MainstayPage>

      <Manager>
        {state.show && state.eventPopoverRef && (
          <Popper placement="auto" referenceElement={state.eventPopoverRef}>
            {({ placement, ref, style }) => (
              <div
                ref={ref}
                style={style}
                data-placement={placement}
                className="popper max-width-550">
                <OutsideClickHandler onClickOutside={handleClose}>
                  <CalendarPopoverContainer
                    regionFilteringEnabled={regionFilteringEnabled}
                    handleConfirmReschedule={date => {
                      setState({
                        ...state,
                        showTimeChangeModal: true,
                        onConfirmReschedule: () =>
                          handleRescheduleCampaign(date),
                      })
                    }}
                    handleDelete={(
                      deleteRecurringCampaignType?: DeleteRecurringCampaignTypeEnum,
                      occurrenceDate?: Date
                    ) => {
                      setState({
                        ...state,
                        showDeletionModal: true,
                        onConfirmDelete: async () =>
                          await handleDelete(
                            deleteRecurringCampaignType,
                            occurrenceDate
                          ),
                      })
                    }}
                    modal={state.modal}
                  />
                </OutsideClickHandler>
              </div>
            )}
          </Popper>
        )}
      </Manager>
      <ConfirmationModal
        title="Delete scheduled campaign?"
        helpText="Are you sure you want to cancel and delete this scheduled campaign?"
        onClose={() => setState({ ...state, showDeletionModal: false })}
        show={state.showDeletionModal}
        confirmButtonText="Confirm"
        onConfirm={async () => {
          if (state.onConfirmDelete) {
            await state.onConfirmDelete()
          }
        }}
        isLoading={state.modal.deleting}
        hideCheckbox
      />
      <ConfirmationModal
        title="Reschedule Campaign?"
        helpText="Are you sure you want to change the date and time of this scheduled campaign?"
        onClose={() => setState({ ...state, showTimeChangeModal: false })}
        show={state.showTimeChangeModal}
        confirmButtonText="Confirm"
        onConfirm={() => {
          if (state.onConfirmReschedule) {
            state.onConfirmReschedule()
          }
        }}
        hideCheckbox
        confirmButtonColor="primary"
      />
    </NavBarContainer>
  )
}
